$sidebar-bg-color: transparent !default;
$sidebar-color: rgba(0, 0, 0, 0.85) !default;
$sidebar-width: 240px !default;
$sidebar-collapsed-width: 80px !default;
$highlight-color: var(--bs-primary) !default;
$submenu-bg-color: rgb(216, 212, 219) !default;
$submenu-bg-color-collapsed: transparent !default;
$icon-bg-color: transparent !default;
$icon-size: 35px !default;
$submenu-indent: 24px !default;
$breakpoint-xs: 480px !default;
$breakpoint-sm: 576px !default;
$breakpoint-md: 768px !default;
$breakpoint-lg: 992px !default;
$breakpoint-xl: 1200px !default;
$breakpoint-xxl: 1600px !default;

.pro-sidebar .pro-menu .pro-menu-item.active > .pro-inner-item:hover {
    color: #fff !important;
}
.pro-sidebar .pro-menu .pro-menu-item.active > .pro-inner-item:hover svg {
    fill: #fff !important;
    color: #fff !important;
}
@import "~react-pro-sidebar/dist/scss/styles.scss";
